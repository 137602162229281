<template>
  <div>
    <div class="bottom1">
      <div class="f1">让我们随时保持联系！</div>
      <div class="f2">
        专业的医疗信息化解决方案提供商，能为您服务是我们的荣幸
      </div>
      <div class="f3">0551-62614668</div>
    </div>
    <div class="bs">
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >皖ICP备2023015185号</a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.bottom1 {
  height: 400px;
  width: 94%;
  min-width: 1200px;
  border-radius: 50px;
  margin: 0 auto;
  background-image: url(../static/bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .f1 {
    font-size: 60px;
    color: #fff;
    font-weight: bold;
    letter-spacing: 5px;
  }
  .f2 {
    font-size: 35px;
    color: #fff;
    letter-spacing: 5px;
    margin: 40px 0;
  }
  .f3 {
    height: 80px;
    width: 50%;
    background: #f8f8f8;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    color: #8c8c8c;
    font-weight: bold;
  }
}
.bs {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: #000;
  margin-bottom: 20px;
}
</style>
