<template>
  <div>
    <div class="bottom1">
      <div>联系方式：0551-62614668</div>
      <div>公司地址:安徽省合肥市高新区黄山路602号合肥国家大学科技园A110</div>
    </div>
    <div class="bs">皖ICP备2023015185号</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.bottom1 {
  height: 100px;
  width: 94%;
  // min-width: 1200px;
  border-radius: 50px;
  background: rgba(#f75005);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // font-size: 35px;
  font-size: 20px;
  color: #ffff;
  //   font-weight: bold;
  // letter-spacing: 20px;
  font-family: "";
  margin: 0 auto;
  line-height: 30px;
  margin-bottom: 20px;
  margin-top: 100px;
}
.bs {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: #000;
  margin-bottom: 20px;
}
// font-family: JXZhuoKai;
</style>
