<template>
  <div class="con">
    <headerVue></headerVue>
    <router-view class="rt"></router-view>
    <footerVue v-if="$route.meta.show"></footerVue>
    <footerVue2 v-else></footerVue2>
  </div>
</template>

<script>
import headerVue from "@/components/header.vue";
import footerVue from "@/components/footer.vue";
import footerVue2 from "@/components/footer2.vue";
export default {
  components: { headerVue, footerVue, footerVue2 },
  data() {
    return {};
  },
  methods: {},
  created() {
    this.$router.push({ name: "index1" });
  },
};
</script>

<style lang="less" scoped>
.con {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  min-width: 1200px;

  .rt {
    margin-top: -8vh;
    min-width: 1200px;
  }
}
</style>
